import React from 'react';
import { images } from '../../constants';
import { SubHeading } from '../../components';
import './Header.css';

const Header = () => (
  <div className='app__header app__wrapper section__padding' id='home'>
   <div className='app__wrapper_info'>
<SubHeading title=''/>
<h1 className='app__header-h1'> Chipotle Mexican

</h1>
<p className='p__opensans3' style={{margin: '2rem 0'}}>
Smagsoplevelser fra Mexico <br></br>
  Oplev ægte mexicansk smag hos vores steakhouse.<br></br>
        Saftige bøffer, krydret krydderier, autentiske retter.<br></br>
        Prøv vores tacos, quesadillas og burritos.<br></br>
        Velkommen til en smagsrejse!<br></br></p>
        <a href='#menu'>
          <button type='button' className='custom__button'>Se vores Menu</button>
        </a>    </div>
    <div className='app__wrapper_img'>
      <img src={images.steakChipotle} alt='header img' />

    </div>
  </div>
);

export default Header;
