import bg from '../assets/bg.png';
import chef from '../assets/chef.png';
import G from '../assets/G.png';
import gallery01 from '../assets/gallery01.png';
import gallery02 from '../assets/gallery02.png';
import gallery03 from '../assets/gallery03.png';
import gallery04 from '../assets/gallery04.png';
import knife from '../assets/knife.png';
import logo from '../assets/logo.png';
import menu from '../assets/menu.png';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';
import welcome from '../assets/welcome.png';
import findus from '../assets/findus.png';
import laurels from '../assets/laurels.png';
import award01 from '../assets/award01.png';
import award02 from '../assets/award02.png';
import award03 from '../assets/award03.png';
import award05 from '../assets/award05.png';
import sign from '../assets/sign.png';
import quote from '../assets/quote.png';
import gericht from '../assets/gericht.png';
import logoMexico from '../assets/logoMexico.png';
import steakChipotle from '../assets/steak-chipotle.png';
import chiliBackground from '../assets/chiliBaggrund.jpg';
import restaurantFront from '../assets/restaurantfront.png';
import smileyIcon from '../assets/smileyikon.jpg';
import insideImage from '../assets/indenforimage.png'
import drinkImage from '../assets/drinkImage.jpg';
import churros from '../assets/churros.png';
import gryde from '../assets/gryde.png';
import fuldmenu from '../assets/fuldmenu.png';
import aftenKort1 from '../assets/aftenkort1.png';
import aftenkort2 from '../assets/aftenkort2.png';
import frokostkort1 from '../assets/frokostkort1.png';
import frokostkort2 from '../assets/frokostkort2.png';
import madImage from '../assets/madImage.jpg';
import nyLogo from '../assets/nyLogo.png';
export default {
  nyLogo,
  madImage,
  frokostkort1,
  frokostkort2,
  aftenkort2,
  aftenKort1,
  fuldmenu,
  gryde,
  churros,
  drinkImage,
  insideImage,
  smileyIcon,
  restaurantFront,
  chiliBackground,
  steakChipotle,
  logoMexico,
  bg,
  chef,
  G,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  knife,
  logo,
  menu,
  overlaybg,
  spoon,
  welcome,
  findus,
  laurels,
  award01,
  award02,
  award03,
  award05,
  sign,
  quote,
  gericht,
};
